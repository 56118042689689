import { HourlyComparativeCache } from 'modules/comparative';
import { HttpPromotionKPIsApi, PromotionKPIsProvider } from 'modules/promotion-kpis';
import KPIs from './KPIs';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <PromotionKPIsProvider api={new HttpPromotionKPIsApi(HourlyComparativeCache)}>
        <KPIs />
    </PromotionKPIsProvider>
);

// For displaying an isolated KPI.
export { default as KPICard } from './components/KPICard';
