// Provisionally coupled with comparative module
import { Comparative } from 'modules/comparative';

import fetcher from 'lib/fetcher';
import { PromotionKPIs } from 'modules/promotion-kpis/domain/PromotionKPIs';
import { MandatoryFlowsDetails } from 'modules/promotion-kpis/domain/mandatory-flows/MandatoryFlowsDetails';
import { MandatoryFlowsSummary } from 'modules/promotion-kpis/domain/mandatory-flows/MandatoryFlowsSummary';
import { Cache } from 'modules/shared';
import { PromotionKPIsApi } from '../../domain/PromotionKPIsApi';
import { PromotionKPIsGoal } from '../../domain/goals/PromotionKPIsGoal';
import { calcMandatoryFlowsSort } from '../../domain/mandatory-flows/calcMandatoryFlowsSort';

export class HttpPromotionKPIsApi implements PromotionKPIsApi {
    constructor(private cache?: Cache<any>) {}

    // Valorization
    async getValorizationSummary({ promotionId }) {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/valorization/summary`);
        return data.content;
    }
    async getValorizationByScope({ promotionId, scope }) {
        const scopeUrlSegment =
            (typeof scope === 'string' && scope) ||
            (typeof scope === 'object' && scope.hasOwnProperty('lerTypeId') && `lerType/${scope.lerTypeId}`) ||
            '';

        const { data } = await fetcher(`api/promotion/${promotionId}/metrics/kpi/valorization/${scopeUrlSegment}`);
        return data.content;
    }

    // Mix
    async getMixSummary({ promotionId }) {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mix/summary`);
        return data.content;
    }
    async getMixByScope({ promotionId, scope }) {
        const scopeUrlSegment =
            (typeof scope === 'string' && scope) ||
            (typeof scope === 'object' && scope.hasOwnProperty('lerTypeId') && `lerTypeId/${scope.lerTypeId}`) ||
            '';

        const { data } = await fetcher(`api/promotion/${promotionId}/metrics/kpi/mix/${scopeUrlSegment}`);
        return data.content;
    }

    // Mandatory flows
    async getMandatoryFlowsSummary({ promotionId }) {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mandatory-flows/summary`);
        const summary: MandatoryFlowsSummary = data.content;

        summary.lerTypes.sort(calcMandatoryFlowsSort);

        return data.content;
    }
    async getMandatoryFlowsDetails({ promotionId }) {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mandatory-flows/details`);
        const details: MandatoryFlowsDetails = data.content;

        details.lerTypes.sort(calcMandatoryFlowsSort);

        return details;
    }

    // Treatment contracts
    async getTreatmentContractsSummary({ promotionId }) {
        const data = await this.PROVISIONAL_getGoalsFromComparative(promotionId);
        return data.treatmentContracts;
    }

    // Traceability update
    async getTraceabilityUpdateSummary({ promotionId }) {
        const data = await this.PROVISIONAL_getGoalsFromComparative(promotionId);
        return data.traceabilityUpdate;
    }

    private async PROVISIONAL_getGoalsFromComparative(promotionId: string) {
        const request = async () => fetcher(`/api/comparative`).then((r) => r.data);
        const response = await (this.cache ? this.cache.cacheQuery(request) : request());
        const comparative: Comparative = response.data;

        const promotion = comparative.find((row) => row.promotion.id.toString() === promotionId.toString());
        if (!promotion) throw new Error(`Promotion ${promotionId} not found in comparative`);

        const goals = promotion.goals;
        return goals;
    }

    // Goals
    async getGoals(criteria) {
        const { promotionId, kpi } = criteria;
        const params = { page: 0, size: 999 };

        const { data } = await fetcher(`/api/promotion/${promotionId}/goals/${kpi}`, { params });

        const goals: PromotionKPIsGoal[] = data.content;
        const goalsSorted = goals.sort((a, b) => {
            // 1. Sort by scope
            if (a.scope.name !== b.scope.name) return b.scope.name.localeCompare(a.scope.name);
            // 2. If scope is the same, sort by severity
            if (a.severity !== b.severity) return (a.severity || 0) - (b.severity || 0);
            // 3. If scope and severity are the same, sort by progress
            if (a.progress !== b.progress) return a.progress - b.progress;
            // 4. If scope, progress and severity are the same, sort by value
            return a.value.value - b.value.value;
        });

        return goalsSorted;
    }

    async getGoalScopes(criteria) {
        const { kpi, ...params } = criteria;
        const { data } = await fetcher(`api/goals/${kpi}/scopes`);

        // Move pagination to back
        if (params.page && params.page > 0) return [];

        // Move search to back
        const scopes = data.content.filter((scope) => scope.name.toLowerCase().includes(params.search?.toLowerCase()));

        return scopes;
    }

    async saveGoal(params) {
        const { promotionId, kpi, goal } = params;

        // Just for clean the object of unnecessary fields for the backend
        const body = structuredClone(goal) as any;
        delete body.uuid;
        delete body.scope.value.name;

        await fetcher.put(`/api/promotion/${promotionId}/goals/${kpi}/${goal.uuid}`, body);
    }

    async removeGoal({ goalId, promotionId }) {
        await fetcher.delete(`/api/promotion/${promotionId}/goals/${goalId}`);
    }

    async getAllGoals({ promotionId }) {
        const kpis = Object.values(PromotionKPIs);

        const requests = kpis.map((kpi) =>
            this.getGoals({ promotionId, kpi }).then((goals) => goals.map((goal) => ({ ...goal, kpi })))
        );

        type Results = Awaited<typeof requests[0]>;

        const goals = await Promise.allSettled(requests)
            .then((res) => res.filter(({ status }) => status === 'fulfilled'))
            .then((res) => res.map((res) => (res as PromiseFulfilledResult<Results>).value))
            .then((res) => res.flat());

        return goals;
    }
}
